import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import { LoginConfig } from '@proppy/proppy-app-login-lib';
 
const LNG_KEY = 'PROPPY_SELECTED_LANGUAGE';
 
@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  selected = '';
  lanCodes=['en','ms','zh','vi']
 
  constructor(private translate: TranslateService, private storage: Storage, private plt: Platform,private router:Router) { }
 
  setInitialAppLanguage() {
    let language = this.translate.getBrowserLang();
    this.selected = this.lanCodes.includes(language)?language:'en';
    this.translate.setDefaultLang(this.selected);
    this.storage.get(LNG_KEY).then(val => {
      if (val) {
        this.setLanguage(val);
        this.selected = val;
      }
    });
  }
 
  // getLanguages() {
  //   return [
  //     { text: 'English', value: 'en', img: 'assets/flags/en.png' },
  //     { text: '中文', value: 'zh', img: 'assets/flags/zh.png' },
  //   ];
  // }

  getLanguages() {
    if(this.router.url.includes('c360')){
      return [
        { text: 'English', value: 'en', img: 'assets/flags/en.png' },
        { text: 'Bahasa Melayu', value: 'ms', img: 'assets/flags/myt.png' },
      ];
    }
    return [
      { text: 'English', value: 'en', img: 'assets/flags/en.png' },
      { text: 'Bahasa Melayu', value: 'ms', img: 'assets/flags/myt.png' },
      { text: '中文', value: 'zh', img: 'assets/flags/zh.png' },
      // { text: 'Vietnamese', value: 'vi', img: 'assets/flags/vnm.svg' },
    ];
  }
 
  setLanguage(lng) {
    this.translate.use(lng);
    this.selected = lng;
    ApiService.SELECTED_LANGUAGE_CODE=lng
    LoginConfig.SELECTED_LANGUAGE_CODE=lng
    this.storage.set(LNG_KEY, lng);
  }
}