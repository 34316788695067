import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingController, ModalController, NavController, Platform } from '@ionic/angular';
import { ProppyWizardPage } from 'src/app/reusables/proppy-wizard/proppy-wizard.page';
import routes_json from '../../../assets/json/routes.json';
import { ApiService } from '../api.service';
import { DeeplinkHelperService } from './deeplink-helper.service';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import * as svg from "save-svg-as-png";
import { cdn_portal, deep_link_base_url, environment, new_deep_link_base_url } from "../../../environments/environment";
import { AdmobService } from './admob-service';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { PermissionService } from '../permission.service';
import { StorageService } from '../storage-service.service';
import QRCode from 'easyqrcodejs';
import { DataFetcherService } from '../data-fetcher/data-fetcher.service';
import { AlertHandlerService } from '../alert-handler.service';
import { TalkService } from '../../api/talk.service';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { File } from '@ionic-native/file/ngx';
import { PreviewAnyFile } from '@ionic-native/preview-any-file/ngx';
import Talk from 'talkjs';
import html2canvas from 'html2canvas';
import { JwtHelperService } from '@auth0/angular-jwt';
import { DBHandlerService } from '../db-handler.service';
import { PushNotifications } from '@capacitor/push-notifications';
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';

@Injectable({
  providedIn: 'root'
})
export class AppHelper {

  proppyID = "";
  slide_out_animation
  slide_in_animation
  currentUserID: string = ""
  loading: HTMLIonLoadingElement;
  private chatBox: Talk.Chatbox;
  constructor(
    private platform: Platform,
    private modalController: ModalController,
    private socialSharing: SocialSharing,
    private plt: Platform,
    private router: Router,
    private geolocation: Geolocation,
    private storage: StorageService,
    private permissionService: PermissionService,
    private __http: DataFetcherService,
    private alertHandler: AlertHandlerService,
    private talkService: TalkService,
    private file: File,
    private previewAnyFile: PreviewAnyFile,
    private loadingController: LoadingController,
    private ngZone:NgZone,
    private navCtrl : NavController
    //  private animationCtrl:AnimationController
  ) {
    // this.setSlideInAnimation()
    // this.setSlideOutAnimation()
    // console.log(routes_json)
  }
  static decodeJwtToken(token) {
    const helper = new JwtHelperService(); 
    if (token != null && token.length > 0) { 
      const tokenSplit = token.split('.'); 
      token = this.deleteCharAtString(0, tokenSplit[1]) + '.' 
        + this.deleteCharAtString(1, tokenSplit[2]) + '.' + this.deleteCharAtString(2, tokenSplit[0]); 
        return helper.decodeToken(token); 
    } 
    return {} 
  }
  static deleteCharAtString = function (index, str) { 
    return str.substr(0, index) + str.substr(index + 1); 
  };
  // 1:Private,2:Public
  static isValidRoute(routingPath) {
    console.log('is valid route...')
    if (routingPath.includes('c360/')) {
      routingPath = routingPath.replace('c360/', '')
    }
    routingPath = routingPath.split('?')[0].replace("/", "");
    var filteredArr = [];   
    // return true;
    console.log('helper - '+routingPath)
    if (AppHelper.isPublicAcess()) {
      const public_routes = routes_json["public"];
      filteredArr = public_routes.filter(f => (routingPath.includes(f) && !routingPath.includes('tabs')))
      if (filteredArr.length > 0) return true
      return false
    }
    return true
  }
  static isPublicAcess() {
    const access_type = localStorage.getItem(ApiService.LBDB_PUBLIC_PRIVATE);
    if (access_type == "2") {
      return true
    }
    return false
  }
  isDesktop() {
    // if(ApiService.IS_SKIP_LOGIN == 1) {
    //   return true;
    // }
    // return false;
    if ((this.platform.is("ios") || this.platform.is("android")) && !this.platform.is("mobileweb")) {
      return false
    }
    return true;
  }

  async isUserLoggedIn(route = true,SenderPID='PROPPY', route_to:any = null) {
    // if (this.isDesktop() && ApiService.IS_SKIP_LOGIN == 0) {
    //   return true
    // }
    var pid = await this.storage.getStorage().get('Proppy_UserID')
    var loggedIn = (pid || '').length > 0
    if (loggedIn) {
      return true
    } else {
      if (route) {
        let url:any = (route_to?route_to:this.router.url)
        await this.storage.setString('route_link',url)
        if (this.isDesktop()) {
          this.router.navigate(['/registration/'+SenderPID])
        } else {
          setTimeout(() => {
            ApiService.DISMISS_APP_POPUP_CB();
            ApiService.IS_APP_POPUP_SHOW = 0;     
          }, 1000);
          this.router.navigate(['/login'])
        }
      }
      return false
    }
  }
  static navigateToHome(router: Router) {
    router.navigate(['/web-landing']);
  }
  static doLogout(router: Router) {
    router.navigate(['/login']);
  }

  static getLangText(langJson, text_key, section_id, selectedCode = ApiService.SELECTED_LANGUAGE_CODE || '') {
    var langCode = selectedCode || ApiService.DEFAULT_LANG_CODE
    const tmpObj = langJson[section_id] || {}
    var langObj = tmpObj[text_key] || {}
    return langObj[langCode] || langObj[ApiService.DEFAULT_LANG_CODE] || ""
  }

  getPlatformInstance() {
    return this.platform
  }

  getCurrentRoute() {
    return this.router.url
  }

  async validateBannerAdsDisplay(routepath = this.getCurrentRoute()) {
    await AdmobService.hideBanner();
    AppHelper.log(routepath + 'validateBannerAdsDisplay')
    AdmobService.advertisement(routepath, this.getPlatformInstance())
  }

  // setSlideInAnimation(){
  //   this.slide_in_animation=this.animationCtrl.create()
  //   .duration(1500)
  //   .iterations(1)
  //   .fromTo('transform', 'translateY(0px)', 'translateY(100px)')
  //   .fromTo('opacity', '1', '0.2');
  // }

  // setSlideOutAnimation(){
  //   this.slide_out_animation=this.animationCtrl.create()
  //   .duration(1500)
  //   .iterations(1)
  //   .fromTo('transform', 'translateX(0px)', 'translateX(100px)')
  //   .fromTo('opacity', '1', '0.2');
  // }

  async openWizard(content) {
    const modal = await this.modalController.create({
      component: ProppyWizardPage,
      cssClass: 'wizard-model',
      keyboardClose: true,
      swipeToClose: true,
      mode: 'ios',
      // enterAnimation:this.slide_in_animation,
      // leaveAnimation:this.slide_out_animation,
      componentProps: {
        items: content
      }
    })
    return await modal.present();
  }
  async socialShare(sMessage, uri = null, type = 1, image_id = null) {
    if (this.plt.is("android")) {
      this.socialSharing.share(
        sMessage,
        sMessage,
        uri,
        null
      );
    } else if (this.plt.is("ios")) {
      if (uri) {
        var accessId = image_id || ((sMessage.split('AccessID=')[1] || '').substring(0, 19))
        if(uri.startsWith('https://cdn.')){
          var imagePath=uri.split('images/')[1]
          var encodedImagePath=(imagePath)
          var msg = (sMessage.split(new_deep_link_base_url)[0] || '') + (deep_link_base_url + 'preview.aspx?AccessID=' + accessId +'&path='+encodedImagePath)
          console.log(msg)
          this.socialSharing.share(
            msg,
            msg,
            null,
            null
          );
          return
        } else if(uri.startsWith(ApiService.BASE_NODE_URL)){
          this.socialSharing.share(
            sMessage,
            sMessage,
            uri,
            null
          );
          return
        }
        var base64 = await fetch(uri);
        var formData = new FormData();
        formData.append('file', await base64.blob(), accessId + '.png');
        let params = new URLSearchParams();
        params.set("ObjectID", accessId);
        this.__http.doPostWithOutHttpHeaders(ApiService.ACCESS_IMAGE_UPLOAD + '?' + params.toString(), formData).subscribe(res => {
          const status = res['status'] || false
          if (status) {
            var msg = (sMessage.split(new_deep_link_base_url)[0] || '') + (deep_link_base_url + 'preview.aspx?AccessID=' + accessId)
            console.log(msg)
            this.socialSharing.share(
              msg,
              msg,
              null,
              null
            );
          } else {
            this.alertHandler.presenToast(ApiService.ERROR_TOAST_MSG)
          }
        })
        return
      }
      this.socialSharing.share(
        sMessage,
        sMessage,
        null,
        null
      );
    }
  }
  static log(content) {
    if (!environment.production) {
      console.log(content)
    }
  }
  replaceSingleQuote(str: string): string {
    const singleQuoteCount = ((str || '').match(/''/g) || []).length;
    if (singleQuoteCount == 0) {
      return (str || '').replace(/'/g, "''");
    }
    return (str || '');
  }
  getCurrentCoordinates() {
    console.log('getCurrentCoordinates')
    this.geolocation.getCurrentPosition().then(async (resp) => {
      let geocoder = await new google.maps.Geocoder();
      let latlng = await new google.maps.LatLng(resp.coords.latitude, resp.coords.longitude);
      let request: google.maps.GeocoderRequest = { location: latlng };
      await geocoder.geocode(request, (results, status) => {
        if (results && results.length > 0) {
          let result = results[0];
          var state = (result['address_components'].filter(f => f.types.includes('administrative_area_level_1')) || [{ long_name: 'Kuala Lumpur' }])[0].long_name
          state = state.includes('Kuala Lumpur') ? 'Kuala Lumpur' : state
          var area = (result['address_components'].filter(f => f.types.includes('sublocality_level_1')) || [{ short_name: '' }])[0].short_name
          this.storage.setString('User_State', state)
          this.storage.setData('User_Address_Obj', {
            lat: resp.coords.latitude + '',
            long: resp.coords.longitude + '',
            state: state,
            area: area,
            formatted_address:result['formatted_address'] || ''
          })
          DBHandlerService.addToLocalDB('User_State', state,()=> {})
          DBHandlerService.addJsonToLocalDB('User_Address_Obj', {
            lat: resp.coords.latitude + '',
            long: resp.coords.longitude + '',
            state: state,
            area: area,
            formatted_address:result['formatted_address'] || ''
          },()=>{})
        }
      });
    }).catch((err) => {
      if (err['message'] == 'Illegal Access') {
        this.permissionService.showGPSModel()
      }
      console.log('Error getting location', err);
    });
  }

  genQr(content,cb,custom_logo='/assets/icon/proppy_logo_forapp_TM.png') {
    var div = document.createElement("div");
    var options = {
      width: 300,
      height: 300,
      drawer: "svg",
      binary: false,
      correctLevel: QRCode.CorrectLevel.H,
      text: content,
      colorDark: "#010600",
      dotScale: "0.8",
      logo: custom_logo,
      PO: "#0320F7",
      timing_H: "#6274FB",
      timing_V: "#6274FB",
      AO: "#0320F7",
      quietZone: 10,
      quietZoneColor: "#FFFFFF",
    };
    new QRCode(div, options);
    setTimeout(() => {
      svg.svgAsPngUri(
        div.getElementsByTagName("svg")[0],
        { excludeCss: true },
        (uri) => {
          cb(uri, div.getElementsByTagName("svg")[0])
        }
      );
    }, 400);
  }

  async sendMessageWithBeep(newConversation: any) {
    if (Object.keys(newConversation).length > 0) {
      this.createNewConversation(newConversation, '')
    }
  }
  async createNewConversation(conversationData, id, cb=null) {
    const user = await this.storage.getStorage().get("user");
    this.currentUserID = user['Proppy_UserID']
    const newConversationData = conversationData;
    const groupId = conversationData['GroupId'] || ""
    const groupName = conversationData['GroupName'] || ""
    const participants = conversationData['Participants'] || []
    if (groupId.length > 0 && participants.length > 0) {
      const session = await this.talkService.createCurrentSession(user);//Auth USer 
      participants.map(participent => {
        participent['photoUrl'] = participent['ProfilePicURL']
      });
      var chatBox = await this.talkService.createConversation(session, user, groupId, groupName, participants);
      chatBox.mount(document.getElementById('global-hidden-talkjs-container'));
      setTimeout(() => {
        if(newConversationData['defaultFileBlob']){
          this.sendAttachment( newConversationData['defaultFileBlob'],newConversationData['defaultFileName'],groupId)
        }
        if(newConversationData['defaultmsg']){
          this.sendMessage(groupId, newConversationData['defaultmsg'], id)
        }
      }, 5000);
      setTimeout(() => {
        if(cb!=null){
          cb(null, true)
        }
      },6000)
    }
  }
  onGetOrCreateConversation(id1, id2, conversationData, visitid,cb=(err,res)=>{}) {
    this.getConversation(id1 + id2, (err, res) => {
      if (err) {
        this.getConversation(id2 + id1, (err, res) => {
          if (err) {
            this.createNewConversation(conversationData, visitid, (err, res) => {
              cb(err, res)
            })
          } else {
            if(conversationData['defaultFileBlob']){
              this.sendAttachment( conversationData['defaultFileBlob'],conversationData['defaultFileName'],res, (err,res) => {
                cb(err,res)
              })
            }
            if(conversationData['defaultmsg']){
              this.sendMessage(res, conversationData['defaultmsg'], visitid)
            }
          }
        })
      } else {
        console.log('convFound')
        if(conversationData['defaultFileBlob']){
          this.sendAttachment(conversationData['defaultFileBlob'],conversationData['defaultFileName'],res, (err,res) => {
            cb(err,res)
          })
        }
        if(conversationData['defaultmsg']){
          this.sendMessage(res, conversationData['defaultmsg'], visitid)
        }
      }
    })
  }
  async getConversation(conversationId, cb) {
    this.__http.doTalkJsApiRequest(ApiService.GET_TALK_JS_CONVERSATION_URL + conversationId).subscribe(res => {
      const status = !res['errorCode'] || false
      if (status) {
        cb(null, conversationId,res)
      } else {
        cb(res['errorCode'], null)
      }
    }, error => {
      cb(error, null)
    })
  }
  async getConversationsByPID(pid, cb) {
    this.__http.doTalkJsApiRequest(ApiService.GET_TALK_JS_CONVERSATION_BY_PID_URL.replace('${userId}',pid)).subscribe(res => {
      const status = !res['errorCode'] || false
      if (status) {
        cb(null,res)
      } else {
        cb(res['errorCode'], null)
      }
    }, error => {
      cb(error, null)
    })
  }
  async createStubConversation(conversationData, conversationId, cb) {
    var ParticipantsList = []
    if (conversationData.Participants.length > 0) {
      if (conversationData.Participants.length > 1) {
        for (var p = 0; p < conversationData.Participants.length; p++) {
          ParticipantsList.push(conversationData.Participants[p].userID)
        }
      } else {
        ParticipantsList = [conversationData.Participants[0].userID, conversationData.Participants[0].userID]
      }
    }
    if (ParticipantsList.length > 0) {
      let params = {
        "participants": ParticipantsList,
        "subject": "Welcome Msg",
        "welcomeMessages": [conversationData.defaultmsg],
        "custom": {},
        "photoUrl": conversationData.Participants[0].ProfilePicURL
      }
      this.__http.doTalkJsApiPutRequest(ApiService.ESTABLISH_TALK_JS_CONVERSATION_URL + conversationId, params).subscribe(res => {
        const status = !res['errorCode'] || false
        if (status) {
          cb(null, conversationId)
        } else {
          cb(res['errorCode'], null)
        }
      }, error => {
        cb(error, null)
      })
    }
  }
  async sendMessage(convid, msg = "Welcome!", visitid, cb=(res)=>{}) {
    const user = await this.storage.getStorage().get("user");
    this.currentUserID = user['Proppy_UserID']
    var postParams = { "ConversationID": convid, "SenderPID": this.currentUserID, "Message": msg }
    this.__http.doPost(ApiService.CHAT_SEND_MSG_URL, postParams).subscribe(async res => {
      const status = res['status'] || false
      if (status) {
        if((visitid || '').length>0) {
          this.updateNotificationStatus(visitid)
        }
        this.alertHandler.presenToast('Message Sent Successfully')
        cb(status)
      } else {
        this.alertHandler.presenToast(res['msg'] || res['error'] || ApiService.ERROR_TOAST_MSG)
      }
    }, error => {
      this.alertHandler.presenToast(error['error'] || ApiService.ERROR_TOAST_MSG)
    })
  }

  updateConversationAccess(convId,newParticipants,leavedParticipants,cb) {
    var postParams = { "ConversationID": convId, "NewParticipants": newParticipants, "LeaveParticipants": leavedParticipants }
    this.__http.doPost(ApiService.ENTER_OR_EXIT_URL, postParams).subscribe(res => {
      const status = res['status'] || false
      if (status) {
        cb()
      }
    })
  }

  updateNotificationStatus(visitid) {
    this.__http.doPut(ApiService.UPDATE_INDIVIDUAL_PROPERTY_VISITOR, { 'is_invitation_sent': 1, 'visit_id': visitid }).subscribe(res => {
      const status = res['status'] || false
      if (status) {
      } else {
        this.alertHandler.presenToast(res['msg'] || res['error'] || ApiService.ERROR_TOAST_MSG)
      }
    }, error => {
      this.alertHandler.presenToast(error['error'] || ApiService.ERROR_TOAST_MSG)
    })
  }

  async downloadFile(file, file_name, file_ext,showPreview = true) {
    let path = null;
    if (this.plt.is('ios')) {
      path = this.file.documentsDirectory;
    } else {
      path = Directory.Documents;
    }
    file = file.split(",")[1] || file
    await Filesystem.checkPermissions()
    var writeRes = await Filesystem.writeFile({
      path: file_name + '_' + (new Date()).getTime() + '.' + file_ext,
      data: file,
      directory: path
    });
    if(showPreview) {
      this.previewAnyFile.preview(writeRes.uri)
    }
  }

  async sendAttachment(blob,fileName,convId,cb=(err,res)=>{}) {
    console.log('sendAttach')
    const user = await this.storage.getStorage().get("user");
    this.currentUserID = user['Proppy_UserID']
    var formData = new FormData();
    formData.append('file', blob, fileName);
    formData.append('filename', fileName);
    this.__http.doTalkJsApiPostRequest(ApiService.SAVE_TALK_JS_ATTACHMENT_URL, formData).subscribe(res => {
      console.log(res)
      this.__http.doTalkJsApiPostRequest(ApiService.GET_TALK_JS_CONVERSATION_URL+`${convId}/messages`, [
        {
          attachmentToken: res['attachmentToken'],
          sender: this.currentUserID,
          type: 'UserMessage',
        },
      ]).subscribe(sendRes => {
        const status = !sendRes['errorCode'] || false
        if (status) {
          cb(null, status)
        } else {
          cb(res['errorCode'], null)
        }
      }, error => {
        cb(error, null)
      })
    }, error => {
      cb(error, null)
    })
  }

  getFileFromHtml(element,cb,type='image/png'){
    this.ngZone.run(async () => {
      html2canvas(element).then(async (canvas) => {
        canvas.getContext('2d');
        var uri =  canvas.toDataURL(type,0.5)
        var base64 = await fetch(uri);
        canvas.toBlob((blob)=>{
          console.log({blob:blob,base64:base64,uri:uri})
          cb(null,{blob:blob,base64:base64,uri:uri})
        },type,1.0)
      }).catch(err=>{
        cb(err,null)
      });
    })
  }

  fileSocialShare(uri){
    console.log(uri,'uri..');
    this.socialSharing.share(
      '',
      '',
      uri,
      null
    );
  }

  routeBackHandler(PrevRoute){
    switch (PrevRoute) {
      case "notification": {
        this.navCtrl.navigateBack("tabs/notification")
        return true;
      }
      default : {
        return false;
      }
    }
  }

  async UpdateLoginAccesstoken(isSkipLogin : boolean = false) {
    await this.alertHandler.presentLoading()
    DBHandlerService.getJsonDataFromStorage(ApiService.LBDB_KEY, (user_info) => {
      var AccessCode = user_info['AccessCode'] || '';
      if(AccessCode.length == 0) {
        this.doLogout(isSkipLogin)
        return;
      }
      this.__http.doPut(ApiService.UPDATE_LOGIN_ACCESSTOKEN, {access_code: AccessCode,login_status: 2,})
      .subscribe((res) => {
        this.doLogout(isSkipLogin)
      }, err=>{
        this.doLogout(isSkipLogin)
      });
    });
  }

  async doLogout(isSkipLogin : boolean = false){
    await this.storage.removeItem('username');
    await this.storage.removeItem('email');
    await this.storage.setBoolean('hasLoggedIn',false);
    await this.storage.removeItem("Proppy_Name");
    await this.storage.removeItem("Proppy_Extension");
    await this.storage.removeItem("Proppy_Email");
    await this.storage.removeItem("Proppy_Mobile");
    await this.storage.removeItem("Proppy_Mobile");
    await this.storage.removeItem("Proppy_UserID");
    await this.storage.removeItem("Proppy_Agent_ID");
    await this.storage.removeItem("Proppy_DeveloperID");
    await this.storage.removeItem("Proppy_Profile_Pic");
    await this.storage.removeItem("proppy_authtoken");
    await this.storage.removeItem("IdentityType");
    await this.storage.removeItem("IdentityHolderCardNo");
    await this.storage.removeItem("IdentityHolderName");
    await this.storage.removeItem("user");
    await this.storage.removeItem("IsMOPFF");
    await this.storage.removeItem("IsAmzRaceInv");
    await this.storage.removeItem(ApiService.HOMES_WELCOME_POPUP_GLOBAL);
    await this.storage.removeItem(ApiService.RESIDENTS_DATA);
    DBHandlerService.clearAllStroage((e) => {
      if(isSkipLogin) {
        ApiService.IS_SKIP_LOGIN = 1;
        this.router.navigateByUrl('/tabs/tab1');
      } else {
        this.router.navigateByUrl('/login');
      }
      this.alertHandler.dismissLoading()
      PushNotifications.removeAllDeliveredNotifications()
    });
  }

  YouTubeUrlGetID(url){
    if((new RegExp(ApiService.REGEXP_VALID_URL)).test(url)){
      url = url.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/|\/shorts\/)/);
      var vID = undefined !== url[2]?url[2].split(/[^0-9a-z_\-]/i)[0]:url[0]
      return (vID && vID.length == 11 ? vID : false)
    }else{
      return false;
    }
  }

  onSettingsClick() {
    // if (this.platform.is('ios')) {
    //   AppLauncher.openUrl({ url: 'app-settings:' });
    // } else if (this.platform.is('android')) {
    //   AppLauncher.openUrl({ url: 'package:com.realtysystems.proppy' }); // replace with your app's package name
    // }
    BarcodeScanner.openAppSettings();
  }

}