// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  baseApiUrl : `https://proffice.proppyapp.biz/`,
  production: true,
  talkjs:{
    appId: "B5CQLXHh",
  },
  initial_route:"login",
  app_version:"05.01.96",
  admob_app_id:"ca-app-pub-5474366311557908~1880456713",
  ios_admob_app_id:'ca-app-pub-5474366311557908~9518972267',
  android_google_map_key:"AIzaSyDjOYLgD-5aHhASmWFiuLxauX5P6jgEBis",
  ios_google_map_key:"AIzaSyAXaIHt5tndDurqfi09abnBR5IZOTDOtSY",
  talk_js_bearer_api_key:"sk_live_3YpaZS6jIwQuxaZyimb156SX2mxq6rEA",
  fd_login_url : `https://www.proppyapp.my/sso/`,
  fd_api_url : `https://proppy.freshdesk.com/`
};


export const url = 'https://api.proppyapp.biz/api';
export const base_path = 'https://api.proppyapp.biz/api/'
export const payment_portal = "https://advertiser.proppyapp.biz/"
export const cdn_portal = "https://cdn.proppyapp.biz/"
export const faq_portal = "https://support.proppyapp.com/"
export const deep_link_base_url = "https://proppyapp.my/"
export const deep_link_hostname = "proppyapp.my"
export const new_deep_link_base_url = "https://proppyapp.my/?AccessID="
export const proppy_web_url ="https://proppyapp.my/"
export const c360_web_url ="https://web.c360.life/"
export const zoloz_api_url = "https://ekyc.proppyapp.net/"
export const zoloz_h5_url = "https://www.proppyapp.biz/ZoloZ/ekyc.html"
export const proffis_deep_link_base_url = "https://proffis.biz/?AccessID="
export const PROFFICE_API_URL = 'https://profficenet.proppyapp.biz/api/'
export const STATIC_API_TOKEN = "cNePnlMRjx63daDd0bU0X33F94cCdvj3zgVEP41y9EeEAzpJh5OgWtJ8r18MZPYAzx"
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
