import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';
import { DataFetcherService } from '../services/data-fetcher/data-fetcher.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {    
    constructor(public router: Router,
        private http: DataFetcherService) {}
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            console.log("error",err)
            if(!request.url.includes('er_logs/error_logs')){
                if(err['error'])
                    this.http.addErrorLog(this.router.url,'',err['error'],request.url,request.body)
            }
            if((err['error'] || {})['isTrusted'] != null) {
                err['error'] = 'isTrusted.'
            } else if(request.url.includes(ApiService.NodeApiUrl)){
                if ((err.status === 401 || err.status === 403) && this.router.url != '/login') {
                    // auto logout if 401 response returned from api
                    this.router.navigate(['/'])
                }
                const error = err.error;
                if(Array.isArray(error['error'] || '')){
                    error['error'] = error['error']?.[0]['msg'] || '';
                }else{
                    var sqlErrNum = (error['error'] || {})['number'] || 0
                    if(sqlErrNum > 0) {
                        error['error']=ApiService.SQL_ERROR_CODE_MESSAGES[sqlErrNum] || ApiService.SQL_ERROR_CODE_MESSAGES[0] || ApiService.ERROR_TOAST_MSG
                    }
                }                          
                return throwError(error);
            }
            return throwError(err);
        }));
    }
}
